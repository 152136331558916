<template>
  <div>登录中</div>
</template>

<script>
import * as dd from "dingtalk-jsapi";

export default {
  name: "ding",
  mounted() {
    console.log("C('ding').corpid", C('ding').corpid);
    const _this = this;
    dd.ready(function () {
      // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
      dd.runtime.permission.requestAuthCode({
        corpId: C('ding').corpid,
        onSuccess: function (result) {
          console.log(result);
          // alert("result" + JSON.stringify(result));
          _this.$cloud
            .login("ding/login?code=" + result.code, {})
            .then((res) => {
              // alert("res" + JSON.stringify(res));
              if (res.type === 1) {
                _this.$cloud.go("/pages/panel/panel");
              } else {
                if(C("isHome")){
                  window.location.replace("/store/preview/index.html")
                }else{
                  _this.$cloud.go("/pages/web/panel");
                }
              }
              // if (res.role === "admin") {
              //   _this.$cloud.go("/pages/select/role");
              // } else {
              //   _this.$cloud.go("/pages/home/home");
              // }
            })
            .catch((error) => {
              _this.$message.error(error);
              // alert("error" + JSON.stringify(error));
              console.log("error", error);
            });
        },
        onFail: function (err) {
          console.log(err);
          alert("登录失败" + JSON.stringify(err));
        },
      });
    });
  },
};
</script>

<style scoped></style>
